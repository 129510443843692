/*@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400;1,700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700;800&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  /*font-family: 'Karla', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;*/
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #F8F9FE;
  /* letter-spacing: 0.02em; */
  /*letter-spacing: -0.03em;*/
  font-size: 92%;
  -webkit-font-smoothing: antialiased;
  /* scroll-behavior: smooth; */
}

h1,h2,h3,h4,h5,h6 {
  /*letter-spacing: 0.01em;*/
  /*letter-spacing: -0.03em;*/
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: none;
}

.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.preloader {
  margin: 0 auto;
}

.copy-text:hover {
  cursor: pointer;
}

.form-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.navbar-dark .navbar-toggler {
  border: none !important;
  outline: none !important;
}


button:focus, .btn:focus {
  outline: none !important;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background: #de6d43;
  border-color: #de6d43;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn.btn-primary {
  background: #FF7341;
  border-color: #FF7341;
  padding-top: 7px;
  padding-bottom: 7px;
}

.btn.btn-primary:focus {
  background: #FF7341;
  border-color: #FF7341;
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  background: #eee;
  border-color: #eee;
  box-shadow: none;
  color: #333;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn.btn-secondary {
  background: #f1f1f1;
  border-color: #f1f1f1;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #333;
}


.btn.btn-secondary:focus {
  background: #eee;
  border-color: #eee;
  color: #333;
  box-shadow: none;
}

.btn-tertiary:not(:disabled):not(.disabled).active, .btn-tertiary:not(:disabled):not(.disabled):active, .show>.btn-tertiary.dropdown-toggle {
  background: #333;
  border-color: #333;
  box-shadow: none;
  color: #fff;
}

.btn-tertiary:not(:disabled):not(.disabled).active:focus, .btn-tertiary:not(:disabled):not(.disabled):active:focus, .show>.btn-tertiary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn.btn-tertiary {
  background: #333;
  border-color: #333;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #fff;
}

.btn.btn-tertiary:focus {
  background: #333;
  border-color: #333;
  color: #fff;
  box-shadow: none;
}

.btn-primary:disabled {
  font-size: 0;
}

.btn-primary:disabled:after {
  content: "Loading...";
  font-size: initial;
}

.dropdown-item {
  /*font-size: 0.9rem;*/
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.dropdown-menu  {
  padding: 0px;
}

.dropdown-item:hover {
  background: #f1f1f1;
}

.dropdown-item:focus {
  background: #f1f1f1;
  color: #333;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.tox.tox-tinymce {
  z-index: 0;
}

.table {
  margin-bottom: 0px;
}

.table-wrap {
  max-width: 100%;
}


@media (max-width: 1000px) {
  .table-wrap {
    overflow-y: visible;
    overflow-x: scroll;
  }
}

table {
  width: 100%;
}

@media (max-width: 767px) {
    .table-responsive .dropdown-menu {
        position: static !important;
    }
}
@media (min-width: 768px) {
    .table-responsive {
        overflow: visible;
    }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.table td, .table th {
  vertical-align: initial;
}

.form-control {
  background: #f4f4f4;
  border: none;
  height: calc(1.5em + 1rem + 2px);
}

.form-control:active, .form-control:focus {
  background: #f4f4f4;
  border: none;
}

.input-group-text {
  background: #f4f4f4;
  border: none;
}

.input-group-prepend .input-group-text {
  border-right: 2px solid #ccc;
  padding-top: 7px;
}

.autocomplete-dropdown-item {
  color: #333;
  padding: 10px 15px;
}

.autocomplete-dropdown-item:hover {
  color: #333;
  background: #f4f4f4;
  cursor: pointer;
}

.autocomplete-dropdown-item:focus, .autocomplete-dropdown-item:active {
  color: #333;
  background: #f4f4f4;
  cursor: pointer;
}

.clean-link, .clean-link:hover {
  text-decoration: none;
  color: #333;
}

.white-clean-link {
  text-decoration: none;
  color: #fff;
}

.white-clean-link:hover {
  text-decoration: none;
  color: #FF7341;
  transition: 0.3s ease;
}

.cleanest-link, .cleanest-link:hover {
  text-decoration: none;
  color: #333;
}

.cleanest-link:hover  {
  color: #FF7341;
  transition: 0.3s ease;
}

.cleanest-blog-link, .cleanest-blog-link:hover {
  text-decoration: none;
  transition: 0.3s ease;
  color: #333;
}

.cleanest-blog-link:hover h2 {
  color: #ff7f50;
  transition: 0.3s ease;
}

td .dropdown-menu.show {
  top: 11px !important;
}

.orange-link {
  text-decoration: none;
  color: #FF7341;
}

.orange-link:hover {
  text-decoration: none;
  color: #FF7341;
}

.clean-link:hover {
  text-decoration: none;
  transition: 0.2s ease;
  color: #FF7341;
}

.clean-link:hover svg {
  color: #FF7341;
  transition: 0.2s ease;
  transform: translateX(3px);
}

.react-toast-notifications__toast__content {
  padding: 10px 12px !important;
  text-align: center;
}

.Toastify__toast-body {
  text-align: center;
}

.badge {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 7px 15px;
  margin-right: 10px;
  background: #f1f1f1;
  color: #333;
  margin-bottom: 10px;
}

.badge-selectable {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.badge-selectable:hover {
  transition: 0.1 ease;
  cursor: pointer;
  color: #fff;
  background: #333;
}

.badge-selectable:focus, .badge-selectable:active {
  transition: 0.1 ease;
  cursor: pointer;
  color: #fff;
  background: #333;
}

.badge-selected {
  color: #333;
  background: #FF734144;
}

.big-title {
  font-weight: bold;
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 25px;
}

.manage-icon {
  margin-right: 10px;
  color: #333;
  opacity: 1;
}


.manage-icon:hover {
  cursor: pointer;
  opacity: 1;
  color: #FF7341;
  transition: 0.3s ease;
}

.modal-content {
  padding: 25px;
}

.modal-header {
  border: none;
}

.modal-footer {
  justify-content: flex-start;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
}

.required-label {
  color: #FF7341;
  margin-left: 3px;
}

.form-check-label {
  -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
}

.form-check-input:focus~.form-check-label::before {
  border-color: #28a745 !important;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
}

.form-check-input:checked~.form-check-label::before {
  border-color: #28a745 !important;
  background-color: #28a745 !important;
}

.form-check-input:focus:not(:checked)~.form-check-label::before {
  border-color: #5bd778 !important;
}

.form-check-input:not(:disabled):active~.form-check-label::before {
  background-color: #d6f5dd !important;
  border-color: #d6f5dd !important;
}
/*
.form-check-input:checked {
  background: #FF7341;
}*/

.right-padding {
  margin-right: 15px;
}

.left-padding {
  padding-left: 15px;
}

.table th {
  border-top: none !important; 
}

/***********************/
/* TOASTIFY
/**********************/

.Toastify__toast-container {
  width: initial;
  width: 100%;
  margin-top: -20px;
}

.Toastify__close-button {
  position: absolute;
  top: 5px;
  right: 12px;
}

.Toastify__toast {
  padding: 10px 40px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  font-family: "Manrope", sans-serif;
}

.Toastify__toast--success {
  /*background: ;*/
  width: 100%;
  background: #2BA95F;
  color: #fff;
}

.Toastify__toast--error {
  width: 100%;
}
 
/***********************/
/* Box
/**********************/

.box {
  background: #FFFFFF;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-top: 10px solid #FF7341;
  border-radius: 4px;
  width: 100%;
  padding: 30px;
  margin-bottom: 50px;
}

.box-title, .small-box-title {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.small-box-title {
  font-size: 1.4rem;
}

.show-white-space {
  white-space: pre-wrap;
}

.admin-page-header, .big-page-header {
  color: #fff;
  font-weight: bold;
}

#jitsi-container iframe {
  border-radius: 4px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
}

.auth-code-input {
  width: 100px;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 auto;
}

.react-share__ShareButton {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
  /* padding-right: 15px; */
}

.grecaptcha-badge { opacity:0;}


.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ril-caption-content.ril__captionContent {
  font-size: 1.5rem;
}